enum ActionsRe {
    // action types
  // CUST_REPORT_LIST = "custReportList",
  // CUST_REPORT_SAVE = "custReportSave",
  // CUST_REPORT_EDIT ="custReportEdit",

  // CUST_NEWS_LIST = "custNewsList",
  // CUST_NEWS_SAVE = "custNewsSave",
  // CUST_NEWS_EDIT ="custNewsEdit",
  // CUST_NEWS_PARENT_CATEGORY = "custParentCatList",
  // CUST_NEWS_CHILD_CATEGORY = "custChildCatList",
  // CUST_NEWS_TYPE = "custNewsType",

  // CUST_ADD_TAG = "custTag",
  // CUST_TAG_LIST = "custTagList",

  // CUST_PRICE_CURRENCY = "custPriceCurrencyy",
  // CUST_PRICE_INCOTERM = "custPriceIncoterm",
  // CUST_PRICE_DELIVERY_TERM = "custPriceDeliveryTerms",

  // CUST_PRICE_INDEX_LIST = "custPriceIndexList",
  // CUST_PRICE_EDIT = "custPriceEdit",  
  // CUST_CITY_LIST = "custCityList",
  // CUST_CAT_LIST = "PriceCategoryList",

  // CUST_ADD_PRICE_INDEX = "custSavePriceIndex",
  // CUST_PRICE_ADD = "custPriceAdd",
  // CUST_PRICE_READING_LIST = "custPriceReadingList",

  // CUST_ATTRIBUTE_VALUE_LIST = "custAttributeValue",
  // CUST_PRICE_ATTRIBUTE = "custAttribute",
  // CUST_PRICE_ATTRIBUTE_SAVE = "custPriceAttrSave",

  // CUST_FILE_UPLOAD = "custFileUpload",

  // CUST_PRO_GRADE = "custProGrade",
  // CUST_PRO_STANDARD = "custProStandard",
  // CUST_ADD_PRO_GRADE = "custAddProGrade",

  // CUST_CAT_GRADE = "custCatGrade",
  // CUST_CAT_STANDARD = "custCatStandard",
  // CUST_ADD_CAT_GRADE = "custAddCatGrade",

  // CUST_PRODUCT_GRADE_MAPPING_LIST = "custProductGradeMapping",
  // CUST_CATEGORY_GRADE_MAPPING_LIST= "custCategoryGradeMapping",
  MM_CATEGORY_LIST = "mmCategoryList",
  }
  
  enum MutationsRe {}
  // mutation types
  
  export { ActionsRe, MutationsRe };
  