import ApiService from "@/core/services/CustomeApiService";
//import CustomMetamarketApiService from "@/core/services/CustomMetamarketApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  user_first_name: string;
  user_id: number;
  role_id: number;
  user_email: string;
  user_token: string;
  s_id: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class CompanyModule extends VuexModule implements UserAuthInfo {
  errors = "";
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
 * Get current user object
 * @returns User
 */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = "";
    JwtService.saveUserData({ 'rakesh_count': 0, 'nikhil_count': 0, 'mansi_count': 0 });
    JwtService.saveToken(user);
  }

  @Mutation
  [Mutations.SET_USER]() {
    return this.user;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = "";
    JwtService.destroyToken();
  }

  @Action
  [Actions.CUST_LOGIN](credentials) {
    ApiService.setHeader();
    return ApiService.post("password_login", credentials)
      .then(({ data }) => {
        if (data.body) {
          this.context.commit(Mutations.SET_AUTH, data.body);
        } else {
          this.context.commit(Mutations.SET_ERROR, data.message);
        }
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CUST_VERIFY_AUTH](payload) {
    if (JwtService.getToken()) {

      // ApiService.setHeader();
      // ApiService.post("verify_token", payload)
      //   .then(({ data }) => {
      //     this.context.commit(Mutations.SET_AUTH, data);
      //   })
      //   .catch(({ response }) => {
      //     this.context.commit(Mutations.SET_ERROR, response.data.errors);
      //     this.context.commit(Mutations.PURGE_AUTH);
      //   });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.CUST_GET_COMPANY_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("company/list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_BRANCH_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("company/branch_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_SELLER_BRANCH_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/get_branches", param)
      .then(({ data }) => {
        if (data.data != "") {
          console.log("Sellerrrrrrrrrrrrrrrrrr")
          console.log(data.data)
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_KMP_COMP_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("company/kmp_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_KMP_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("kmp/list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_PINCODE](param) {
    ApiService.setHeader();
    return ApiService.post("location/search_pincodes", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_ADD_BRANCH](param) {
    ApiService.setHeader();
    return ApiService.post("company/branch_add", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_UPDATE_BRANCH](param) {
    ApiService.setHeader();
    return ApiService.post("company/branch_update", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_ADD_COMPANY](param) {
    ApiService.setHeader();
    return ApiService.post("company/create", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_ADD_KMP](param) {
    ApiService.setHeader();
    return ApiService.post("kmp/create", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_UPDATE_KMP](param) {
    ApiService.setHeader();
    return ApiService.post("kmp/update", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_LINK_KMP](param) {
    ApiService.setHeader();
    return ApiService.post("company/kmp_add", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_DIN](param) {
    ApiService.setHeader();
    return ApiService.post("kmp/list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_EDIT_BRANCH](param) {
    ApiService.setHeader();
    return ApiService.post("company/branch_edit", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_EDIT_COMPANY](param) {
    ApiService.setHeader();
    return ApiService.post("company/update", param)
      .then(({ data }) => {
        if (!data.is_error) {
          return { "data": 1 }
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_ADD_CATEGORY](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_create", param)
      .then(({ data }) => {
        if (data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_INDUSTRY_CATEGORY](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_add_page", param)
      .then(({ data }) => {
        if (data.body.industry_types_pc != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_FORM_CATEGORY](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_add_page", param)
      .then(({ data }) => {
        if (data.body.form != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_Stage_CATEGORY](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_add_page", param)
      .then(({ data }) => {
        if (data.body.stage != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_Length_CATEGORY](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_add_page", param)
      .then(({ data }) => {
        if (data.body.length != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_TAGLIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/list_tag", param)
      .then(({ data }) => {
        if (data.data.page_data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_SHAPE_CATEGORY](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_add_page", param)
      .then(({ data }) => {
        if (data.body.product != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_CATEGORY_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_PARENT_CATEGORY](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_list_dp", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_CATEGORY](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_search", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_HSN_CATEGORY](param) {
    ApiService.setHeader();
    return ApiService.post("product/search_hsn", param)
      .then(({ data }) => {
        if (data.data != "") {
          console.log("HSN")
          console.log(data)
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_STANDARD_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_standard_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          console.log("StandardList")
          console.log(data)
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_ADD_PRODUCT_STANDARD](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_standard_add", param)
      .then(({ data }) => {
        if (data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_STANDARD_MASTER_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/master_standards_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          console.log("StandardList")
          console.log(data)
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_ADD_PRODUCT_GRADE](param) {
    ApiService.setHeader();
    return ApiService.post("product/grade_add", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          console.log("StandardList")
          console.log(data)
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_GRADE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/grade_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          console.log(data)
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_UPDATE_CATEGORY](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_update", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_UPDATE_PRODUCT_STANDARD](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_standard_update", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_UPDATE_GRADE](param) {
    ApiService.setHeader();
    return ApiService.post("product/grade_update", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_GRADE_STANDARD](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_standard_search", param)
      .then(({ data }) => {
        if (data.data.page_data != "") {
          console.log(data)
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_LINK_PRODUCT_STANDARD_CAT](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_standard_mapping_create", param)
      .then(({ data }) => {
        if (data != "") {
          console.log(data)
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }


  @Action
  [Actions.CUST_ADD_ATTRIBUTE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_attribute_mapping_page", param)
      .then(({ data }) => {
        if (data.data.attribute_list != "") {
          console.log("44444444444444")
          console.log(data)
          console.log(data)
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_MAPPING_ATTRIBUTE](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_attribute_mapping_save", param)
      .then(({ data }) => {
        if (data != "") {
          console.log(data)
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_ATTRIBUTE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_attribute_mapping_list", param)
      .then(({ data }) => {
        if (data.data.attribute_list != "") {
          console.log(data.data.attribute_list)
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_MAKING](param) {
    ApiService.setHeader();
    return ApiService.post("product/add_page", param)
      .then(({ data }) => {
        if (data.body.making_process_list != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_COMPOSITION](param) {
    ApiService.setHeader();
    return ApiService.post("product/add_page", param)
      .then(({ data }) => {
        if (data.body.composition_types_list != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_ADD_PRODUCT](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_create", param)
      .then(({ data }) => {
        if (data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_SHAPE](param) {
    ApiService.setHeader();
    return ApiService.post("product/add_page", param)
      .then(({ data }) => {
        if (data.body.shape_list != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_list", param)
      .then(({ data }) => {
        if (data != "") {
          console.log(data)
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_UPDATE](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_update", param)
      .then(({ data }) => {
        if (data != "") {
          console.log(data)
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_ATTRIBUTE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/attribute_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          console.log(data.data.result_list)
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_COMPANY_PAGE_DATA]() {
    ApiService.setHeader();
    return ApiService.post("company/add_page", {})
      .then(({ data }) => {
        if (data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_COMPANY_TRADE_TYPE_LIST_DATA]() {
    ApiService.setHeader();
    return ApiService.post("company/tradetype_list", {})
      .then(({ data }) => {
        if (data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_ADD_TAG](param) {
    ApiService.setHeader();
    return ApiService.post("product/create_tag", param)
      .then(({ data }) => {
        if (data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_HSN_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/hsn_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_ADDRESS_TITLE_LIST_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("company/dp_branchtitletype_list", param)
      .then(({ data }) => {
        if (data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_ADD_HSN](param) {
    ApiService.setHeader();
    return ApiService.post("product/hsn_create", param)
      .then(({ data }) => {
        if (data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_UPDATE_HSN](param) {
    ApiService.setHeader();
    return ApiService.post("product/hsn_update", param)
      .then(({ data }) => {
        if (data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_ADD_CONTACT](param) {
    ApiService.setHeader();
    return ApiService.post("company/contact_create", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_UPDATE_CONTACT](param) {
    ApiService.setHeader();
    return ApiService.post("company/contact_update", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_LIST_CONTACT](param) {
    ApiService.setHeader();
    return ApiService.post("company/contact_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_DESIGNATION_CONTACT](param) {
    ApiService.setHeader();
    return ApiService.post("company/contact_add", param)
      .then(({ data }) => {
        if (data.body.business_natures != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }



  @Action
  [Actions.CUST_GET_PRODUCT_BRAND](param) {
    ApiService.setHeader();
    return ApiService.post("product/brand_list", param)
      .then(({ data }) => {
        if (data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_COMPANY_BRAND](param) {
    ApiService.setHeader();
    return ApiService.post("company/brand/list", param)
      .then(({ data }) => {
        if (data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  // @Action
  // [Actions.CUST_ADD_PRODUCT_BRAND](param) {
  //   ApiService.setHeader();
  //   return ApiService.post("product/brand_create", param)
  //     .then(({ data }) => {
  //       if (data.data != "") {
  //         return data
  //       } else {
  //         return { "data": 0 }
  //       }
  //     })
  //     .catch(({ response }) => {
  //       return { "data": 0 }
  //     });
  // }

  @Action
  [Actions.CUST_ADD_PRODUCT_BRAND](param) {
    ApiService.setHeader();
    return ApiService.post("company/brand/create", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  // @Action
  // [Actions.CUST_UPDATE_PRODUCT_BRAND](param) {
  //   ApiService.setHeader();
  //   return ApiService.post("product/brand_update", param)
  //     .then(({ data }) => {
  //       if (data.data != "") {
  //         return data
  //       } else {
  //         return { "data": 0 }
  //       }
  //     })
  //     .catch(({ response }) => {
  //       return { "data": 0 }
  //     });
  // }

  @Action
  [Actions.CUST_UPDATE_PRODUCT_BRAND](param) {
    ApiService.setHeader();
    return ApiService.post("company/brand/update", param)
      .then(({ data }) => {
        debugger
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_PRODUCT_BRAND](param) {
    ApiService.setHeader();
    return ApiService.post("product/brand_search", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_PRODUCT_UOM](param) {
    ApiService.setHeader();
    return ApiService.post("product/uom_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          console.log("Data")
          console.log(data)
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_PRODUCT_CURRENCY](param) {
    ApiService.setHeader();
    return ApiService.post("currency/list", param)
      .then(({ data }) => {
        if (data.data != "") {
          console.log("Data")
          console.log(data)
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  // @Action
  // [Actions.CUST_ADD_PRODUCT_PRICE](param) {
  //   ApiService.setHeader();
  //   return ApiService.post("product/price_save",param)
  //     .then(({ data }) => {
  //       if(data.data != ""){  
  //         console.log("Data")
  //         console.log(data)        
  //         return data
  //       }else{
  //         return {"data" : 0}
  //       }
  //     })
  //     .catch(({ response }) => {
  //       return {"data" : 0}
  //     });
  // }
  @Action
  [Actions.CUST_ADD_PRODUCT_INVENTORY](param) {
    ApiService.setHeader();
    return ApiService.post("product_inventory_price/save", param)
      .then(({ data }) => {
        if (data.data != "") {
          console.log("Data")
          console.log(data)
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }
  @Action
  [Actions.CUST_GET_PRODUCT_PRICE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product_inventory_price/list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }
  @Action
  [Actions.CUST_GET_PRODUCT_INVENTORY_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product_inventory/list", param)
      .then(({ data }) => {
        if (data.data != "") {
          console.log("Data")
          console.log(data)
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_PRODUCT_DELIVERY_TERM](param) {
    ApiService.setHeader();
    return ApiService.post("delivery_term/list", param)
      .then(({ data }) => {
        if (data.data != "") {
          console.log("Deliveryyyy")
          console.log(data)
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_PRODUCT_INCOTERM](param) {
    ApiService.setHeader();
    return ApiService.post("product_incoterm/list", param)
      .then(({ data }) => {
        if (data.data != "") {
          console.log("Data")
          console.log(data)
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }
  @Action
  [Actions.CUST_ADD_UOM_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/uom_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }
  @Action
  [Actions.CUST_LINK_ATTRIBUTE_RANGE](param) {
    ApiService.setHeader();
    return ApiService.post("attribute_range/save", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }
  @Action
  [Actions.CUST_ATTRIBUTE_RANGE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("attribute_range/list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_SELECTBOX](param) {
    ApiService.setHeader();
    return ApiService.post("product/dp_product_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_ATTRIBUTE](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_attribute_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_PRODUCT_ATTRIBUTE_VALUE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_attribute_json_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_PRODUCT_MAPPING_ATTRIBUTE](param) {
    ApiService.setHeader();
    return ApiService.post("product/attribute_mapping_create", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }
  @Action
  [Actions.CUST_PRODUCT_ATTRIBUTE_LIST_DISPLAY](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_attribute_mapping_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_ADD_CAT_IMAGE](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_image_upload", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  ///////////////////////////Rsearch apis///////////////////////////////////


  @Action
  [Actions.CUST_REPORT_SAVE](param) {
    ApiService.setHeader();
    return ApiService.post("research_reports/report_save", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_REPORT_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("research_reports/report_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_REPORT_EDIT](param) {
    ApiService.setHeader();
    return ApiService.post("research_reports/report_edit", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_NEWS_SAVE](param) {
    ApiService.setHeader();
    return ApiService.post("research_news/news_save", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_NEWS_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("research_news/news_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_NEWS_EDIT](param) {
    ApiService.setHeader();
    return ApiService.post("research_news/news_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_NEWS_PARENT_CATEGORY](param) {
    ApiService.setHeader();
    return ApiService.post("research_common/parent_categories", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_NEWS_CHILD_CATEGORY](param) {
    ApiService.setHeader();
    return ApiService.post("research_common/child_categories", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }
  @Action
  [Actions.CUST_NEWS_TYPE](param) {
    ApiService.setHeader();
    return ApiService.post("research_news/news_type_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_TAG](param) {
    ApiService.setHeader();
    return ApiService.post("product/create_tag", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_TAG_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/list_tag", param)
      .then(({ data }) => {
        if (data.data.page_data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_CAT_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("research_category/list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }
  @Action
  [Actions.CUST_PRICE_CURRENCY](param) {
    ApiService.setHeader();
    return ApiService.post("research_common/currency_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }
  @Action
  [Actions.CUST_CITY_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("research_common/city_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_PRICE_INCOTERM](param) {
    ApiService.setHeader();
    return ApiService.post("research_common/incoterms_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_PRICE_DELIVERY_TERM](param) {
    ApiService.setHeader();
    return ApiService.post("research_common/delivery_terms_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_PRICE_INDEX_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("research_price_index/template_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_PRICE_EDIT](param) {
    ApiService.setHeader();
    return ApiService.post("research_price_index/template_edit", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_ADD_PRICE_INDEX](param) {
    ApiService.setHeader();
    return ApiService.post("research_price_index/template_save", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_PRICE_ADD](param) {
    ApiService.setHeader();
    return ApiService.post("research_price_index/template_price_save", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_PRICE_READING_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("research_price_index/template_price_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_PRICE_ATTRIBUTE](param) {
    ApiService.setHeader();
    return ApiService.post("research_price_index/template_attribute_list", param)
      .then(({ data }) => {
        if (data.data.attribute_list != "") {
          console.log("data")
          console.log(data)
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_ATTRIBUTE_VALUE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("research_price_index/template_category_attributes", param)
      .then(({ data }) => {
        if (data.data != "") {
          console.log("AAAAAAAAAAAAA")
          console.log(data)
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_PRICE_ATTRIBUTE_SAVE](param) {
    ApiService.setHeader();
    return ApiService.post("research_price_index/template_attribute_save", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }


  @Action
  [Actions.CUST_FILE_UPLOAD](param) {
    ApiService.setHeader();
    return ApiService.post("research_price_index/template_price_bulk_upload", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_PRO_GRADE](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_grade_list_dropdown", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_PRO_STANDARD](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_category_standard_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_ADD_PRO_GRADE](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_grade_mapping_create", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_CAT_GRADE](param) {
    ApiService.setHeader();
    return ApiService.post("product/grade_by_standard", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_CAT_STANDARD](param) {
    ApiService.setHeader();
    return ApiService.post("product/standard_list_dropdown", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_ADD_CAT_GRADE](param) {
    ApiService.setHeader();
    return ApiService.post("product/grade_mapping_create", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_CATEGORY_GRADE_MAPPING_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_grade_mapping_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_PRODUCT_GRADE_MAPPING_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_grade_mapping_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

  @Action
  [Actions.CUST_PRODUCT_MATERIAL_CONDITION](param) {
    ApiService.setHeader();
    return ApiService.post("product_material_condition", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data
        } else {
          return { "data": 0 }
        }
      })
      .catch(({ response }) => {
        return { "data": 0 }
      });
  }

}



