import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/custom-form",
        name: "customForm",
        component: () => import("@/components/custom/Form.vue"),
      },
      {
        path: "/custom-list",
        name: "customList",
        component: () => import("@/components/custom/List.vue"),
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/Builder.vue"),
      },
      {
        path: "/crafted/pages/profile",
        name: "profile",
        component: () => import("@/components/page-layouts/Profile.vue"),
        children: [
          {
            path: "overview",
            name: "profile-overview",
            component: () =>
              import("@/views/crafted/pages/profile/Overview.vue"),
          },
          {
            path: "projects",
            name: "profile-projects",
            component: () =>
              import("@/views/crafted/pages/profile/Projects.vue"),
          },
          {
            path: "campaigns",
            name: "profile-campaigns",
            component: () =>
              import("@/views/crafted/pages/profile/Campaigns.vue"),
          },
          {
            path: "documents",
            name: "profile-documents",
            component: () =>
              import("@/views/crafted/pages/profile/Documents.vue"),
          },
          {
            path: "connections",
            name: "profile-connections",
            component: () =>
              import("@/views/crafted/pages/profile/Connections.vue"),
          },
          {
            path: "activity",
            name: "profile-activity",
            component: () =>
              import("@/views/crafted/pages/profile/Activity.vue"),
          },
        ],
      },
      {
        path: "/crafted/pages/wizards/horizontal",
        name: "horizontal-wizard",
        component: () => import("@/views/crafted/pages/wizards/Horizontal.vue"),
      },
      {
        path: "/crafted/pages/wizards/vertical",
        name: "vertical-wizard",
        component: () => import("@/views/crafted/pages/wizards/Vertical.vue"),
      },
      {
        path: "/crafted/account",
        name: "account",
        component: () => import("@/views/crafted/account/Account.vue"),
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () => import("@/views/crafted/account/Overview.vue"),
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/crafted/account/Settings.vue"),
          },
        ],
      },
      {
        path: "/apps/customers/getting-started",
        name: "apps-customers-getting-started",
        component: () => import("@/views/apps/customers/GettingStarted.vue"),
      },
      {
        path: "/products",
        name: "apps-product-listing",
        component: () => import("@/views/apps/product/Products.vue"),
      },
      {
        path: "/product-details/:id",
        name: "apps-product-details",
        props: true,
        component: () => import("@/views/apps/product/ProductDetails.vue"),
      },
      {
         path: "/products/categories",
         name: "apps-categories-listing",
         props: true,
         component: () => import("@/views/apps/product/ProductCategories.vue"),
       },
       {
        path: "/products/categories/categories-details/:id",
        name: "apps-categories-details",
        props: true,
        component: () => import("@/views/apps/product/ProductCategoriesDetails.vue"),
      },
      {
        path: "/products/product-attribute",
        name: "apps-attribute-listing",
        props: true,
        component: () => import("@/views/apps/product/AttributeList.vue"),
      },
       {
        path: "/products/product-standard",
        name: "apps-product-standard-listing",
        component: () => import("@/views/apps/product/ProductStandard.vue"),
      },
      {
        path: "/products/product-standard-details/:id",
        name: "apps-product-standard-details-listing",
        props: true,
        component: () => import("@/views/apps/product/ProductStandardDetails.vue"),
      },
      {
        path: "/products/product-grade",
        name: "apps-product-grade-listing",
        component: () => import("@/views/apps/product/ProductGrade.vue"),
      },
      {
        path: "/products/product-hsn",
        name: "apps-product-hsn-listing",
        component: () => import("@/views/apps/product/ProductHsn.vue"),
      },
      {
        path: "/products/product-hsn-details/:id",
        name: "apps-product-hsn-details",
        props: true,
        component: () => import("@/views/apps/product/ProductHsnDetails.vue"),
      },
      {
        path: "/products/product-grade-details/:id",
        name: "apps-grade-details",
        props: true,
        component: () => import("@/views/apps/product/ProductGradeDetails.vue"),
      },
      {
        path: "/products/product-brand",
        name: "apps-product-brand-list",
        props: true,
        component: () => import("@/views/apps/product/ProductBrand.vue"),
      },
      {
        path: "/products/product-brand-details/:id",
        name: "apps-product-brand-details",
        props: true,
        component: () => import("@/views/apps/product/ProductBrandDetails.vue"),
      },
      // {
      //   path: "/products/product-prices",
      //   name: "apps-product-prices-list",
      //   props: true,
      //   component: () => import("@/views/apps/product/ProductPrices.vue"),
      // },
      {
        path: "/research/news",
        name: "apps-news-list",
        props: true,
        component: () => import("@/views/apps/research/News.vue"),
      },
      {
        path: "/research/news-details/:id",
        name: "apps-news-details",
        props: true,
        component: () => import("@/views/apps/research/NewsDetailsView.vue"),
      },
      {
        path: "/research/news-add",
        name: "apps-news-add",
        props: true,
        component: () => import("@/views/apps/research/AddNewsForm.vue"),
      },
      {
        path: "/research/news-edit/:id",
        name: "apps-news-edit",
        props: true,
        component: () => import("@/views/apps/research/EditNewsForm.vue"),
      },
      {
        path: "/research/reports",
        name: "apps-reports-list",
        props: true,
        component: () => import("@/views/apps/research/Reports.vue"),
      },
      {
        path: "/research/reports-details/:id",
        name: "apps-reports-details",
        props: true,
        component: () => import("@/views/apps/research/ReportsDetailsView.vue"),
      },
      {
        path: "/research/reports-add",
        name: "apps-reports-add",
        props: true,
        component: () => import("@/views/apps/research/AddReportsForm.vue"),
      },
      {
        path: "/research/reports-edit/:id",
        name: "apps-reports-edit",
        props: true,
        component: () => import("@/views/apps/research/EditReportsForm.vue"),
      },
      {
        path: "/research/price-index",
        name: "apps-price-list",
        props: true,
        component: () => import("@/views/apps/research/PriceIndexList.vue"),
      },
      {
        path: "/research/price-index-details/:id",
        name: "apps-price-details",
        props: true,
        component: () => import("@/views/apps/research/PriceIndexDetailsView.vue"),
      },
      {
        path: "/company",
        name: "apps-companies-listing",
        component: () => import("@/views/apps/companies/CompaniesListing.vue"),
      },
      {
        path: "/company/company-details/:id",
        name: "apps-companies-detaills",
        props: true,
        component: () => import("@/views/apps/companies/CompaniesDetails.vue"),
      },
      {
        path: "/company/branch-details/:id",
        name: "apps-branch-detaills",
        props: true,
        component: () => import("@/views/apps/branches/BranchesDetails.vue"),
      },
      {
        path: "/company/contacts",
        name: "apps-crm-contacts",
        component: () => import("@/views/apps/crm/ContactsList.vue"),
      },
      {
        path: "/company/contact-details/:id",
        name: "apps-crm-contacts-details",
        props: true,
        component: () => import("@/views/apps/crm/ContactDetailsView.vue"),
      },
      {
        path: "/company/kmps",
        name: "apps-companies-kmps",
        component: () => import("@/views/apps/kmps/KmpsListing.vue"),
      },
      {
        path: "/company/kmps-details/:id",
        name: "apps-companies-kmps-details",
        props: true,
        component: () => import("@/views/apps/kmps/KmpDetailsView.vue"),
      },
      {
        path: "/company/kmps-comp-details/:id",
        name: "apps-companies-kmps-comp-details",
        props: true,
        component: () => import("@/views/apps/kmps/KmpCompView.vue"),
      },
      {
        path: "/apps/customers/customer-details",
        name: "apps-customers-details",
        component: () => import("@/views/apps/customers/CustomersListing.vue"),
      },
      {
        path: "/subscriptions/getting-started",
        name: "subscriptions-getting-started",
        component: () =>
          import("@/views/apps/subscriptions/GettingStarted.vue"),
      },
      {
        path: "/subscriptions/subscription-list",
        name: "subscriptions-subscription-list",
        component: () =>
          import("@/views/apps/subscriptions/SubscriptionList.vue"),
      },
      {
        path: "/subscriptions/add-subscription",
        name: "subscriptions-add-subscription",
        component: () =>
          import("@/views/apps/subscriptions/AddSubscription.vue"),
      },
      {
        path: "/subscriptions/view-subscription",
        name: "subscriptions-view-subscription",
        component: () =>
          import("@/views/apps/subscriptions/ViewSubscription.vue"),
      },
      {
        path: "/apps/calendar",
        name: "apps-calendar",
        component: () => import("@/views/apps/Calendar.vue"),
      },
      {
        path: "/apps/chat/private-chat",
        name: "apps-private-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/group-chat",
        name: "apps-group-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/drawer-chat",
        name: "apps-drawer-chat",
        component: () => import("@/views/apps/chat/DrawerChat.vue"),
      },
      {
        path: "/crafted/modals/general/invite-friends",
        name: "modals-general-invite-friends",
        component: () =>
          import("@/views/crafted/modals/general/InviteFriends.vue"),
      },
      {
        path: "/crafted/modals/general/view-user",
        name: "modals-general-view-user",
        component: () => import("@/views/crafted/modals/general/ViewUsers.vue"),
      },
      {
        path: "/crafted/modals/general/upgrade-plan",
        name: "modals-general-upgrade-plan",
        component: () =>
          import("@/views/crafted/modals/general/UpgradePlan.vue"),
      },
      {
        path: "/crafted/modals/general/share-and-earn",
        name: "modals-general-share-and-earn",
        component: () =>
          import("@/views/crafted/modals/general/ShareAndEarn.vue"),
      },
      {
        path: "/crafted/modals/forms/new-target",
        name: "modals-forms-new-target",
        component: () => import("@/views/crafted/modals/forms/NewTarget.vue"),
      },
      {
        path: "/crafted/modals/forms/new-card",
        name: "modals-forms-new-card",
        component: () => import("@/views/crafted/modals/forms/NewCard.vue"),
      },
      {
        path: "/crafted/modals/forms/new-address",
        name: "modals-forms-new-address",
        component: () => import("@/views/crafted/modals/forms/NewAddress.vue"),
      },
      {
        path: "/crafted/modals/forms/create-api-key",
        name: "modals-forms-create-api-key",
        component: () =>
          import("@/views/crafted/modals/forms/CreateApiKey.vue"),
      },
      {
        path: "/crafted/modals/wizards/two-factor-auth",
        name: "modals-wizards-two-factor-auth",
        component: () =>
          import("@/views/crafted/modals/wizards/TwoFactorAuth.vue"),
      },
      {
        path: "/crafted/modals/wizards/create-app",
        name: "modals-wizards-create-app",
        component: () => import("@/views/crafted/modals/wizards/CreateApp.vue"),
      },
      {
        path: "/crafted/modals/wizards/create-account",
        name: "modals-wizards-create-account",
        component: () =>
          import("@/views/crafted/modals/wizards/CreateAccount.vue"),
      },
      {
        path: "/crafted/widgets/lists",
        name: "widgets-list",
        component: () => import("@/views/crafted/widgets/Lists.vue"),
      },
      {
        path: "/crafted/widgets/statistics",
        name: "widgets-statistics",
        component: () => import("@/views/crafted/widgets/Statistics.vue"),
      },
      {
        path: "/crafted/widgets/charts",
        name: "widgets-charts",
        component: () => import("@/views/crafted/widgets/Charts.vue"),
      },
      {
        path: "/crafted/widgets/mixed",
        name: "widgets-mixed",
        component: () => import("@/views/crafted/widgets/Mixed.vue"),
      },
      {
        path: "/crafted/widgets/tables",
        name: "widgets-tables",
        component: () => import("@/views/crafted/widgets/Tables.vue"),
      },
      {
        path: "/crafted/widgets/feeds",
        name: "widgets-feeds",
        component: () => import("@/views/crafted/widgets/Feeds.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/under-construction",
    name: "under-construction",
    component: () => import("@/views/crafted/authentication/devlopment.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.CUST_VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
