import { App } from "vue";
import VueAxios2 from "vue-axios";
import axios2 from "axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";

/**
 * @description service to call HTTP request via Axios
 */
class CustomMetamarketApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance2: App;
  

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    CustomMetamarketApiService.vueInstance2 = app;
    CustomMetamarketApiService.vueInstance2.use(VueAxios2, { axios2: axios2 });    
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    CustomMetamarketApiService.vueInstance2.axios.defaults.baseURL = process.env.VUE_APP_CUSTOME_APP_API_MM_URL;
    CustomMetamarketApiService.vueInstance2.axios.defaults.headers.common["x-api-key"] = process.env.VUE_APP_CUSTOME_APP_API_MM_KEY;
    CustomMetamarketApiService.vueInstance2.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return CustomMetamarketApiService.vueInstance2.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return CustomMetamarketApiService.vueInstance2.axios.get(`${resource}/${slug}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {    
    return CustomMetamarketApiService.vueInstance2.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return CustomMetamarketApiService.vueInstance2.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return CustomMetamarketApiService.vueInstance2.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return CustomMetamarketApiService.vueInstance2.axios.delete(resource);
  }
}

export default CustomMetamarketApiService;
