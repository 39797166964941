import CustomMetamarketApiService from "@/core/services/CustomMetamarketApiService";
import ApiService from "@/core/services/CustomeApiService";
import JwtService from "@/core/services/JwtService";
import { ActionsRe, MutationsRe } from "@/store/enums/StoreResearchEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class ResearchModule extends VuexModule {
    @Action
    [ActionsRe.MM_CATEGORY_LIST](param) {
        CustomMetamarketApiService.setHeader();
        return CustomMetamarketApiService.post("categories",param)
        .then(({ data }) => {
          if(data.data != ""){  
            return data
          }else{
            return {"data" : 0}
          }
        })
        .catch(({ response }) => {
          return {"data" : 0}
        });
    }
//   @Action
//   [ActionsRe.CUST_REPORT_SAVE](param) {
//       CustomMetamarketApiService.setHeader();
//     return CustomMetamarketApiService.post("research_reports/save",param)
//       .then(({ data }) => {
//         if(data.data != ""){  
//           return data
//         }else{
//           return {"data" : 0}
//         }
//       })
//       .catch(({ response }) => {
//         return {"data" : 0}
//       });
//   }

//   @Action
//     [ActionsRe.CUST_REPORT_LIST](param) {
//       CustomMetamarketApiService.setHeader();
//       return CustomMetamarketApiService.post("research_reports/crm_list", param)
//         .then(({ data }) => {
//           if(data.data.result_list != ""){
//             return data
//           }else{
//             return {"data" : 0}
//           }
//         })
//         .catch(({ response }) => {
//           return {"data" : 0}
//         });
//     }

//     @Action
//     [ActionsRe.CUST_REPORT_EDIT](param) {
//       CustomMetamarketApiService.setHeader();
//       return CustomMetamarketApiService.post("research_reports/edit",param)
//         .then(({ data }) => {
//           if(data.data != ""){  
//             return data
//           }else{
//             return {"data" : 0}
//           }
//         })
//         .catch(({ response }) => {
//           return {"data" : 0}
//         });
//     }
    
//   @Action
//   [ActionsRe.CUST_NEWS_SAVE](param) {
//       CustomMetamarketApiService.setHeader();
//     return CustomMetamarketApiService.post("research_news/save",param)
//       .then(({ data }) => {
//         if(data.data != ""){  
//           return data
//         }else{
//           return {"data" : 0}
//         }
//       })
//       .catch(({ response }) => {
//         return {"data" : 0}
//       });
//   }

//     @Action
//     [ActionsRe.CUST_NEWS_LIST](param) {
//       CustomMetamarketApiService.setHeader();
//       return CustomMetamarketApiService.post("research_news/list",param)
//         .then(({ data }) => {
//           if(data.data != ""){  
//             return data
//           }else{
//             return {"data" : 0}
//           }
//         })
//         .catch(({ response }) => {
//           return {"data" : 0}
//         });
//     }

//     @Action
//     [ActionsRe.CUST_NEWS_EDIT](param) {
//       CustomMetamarketApiService.setHeader();
//       return CustomMetamarketApiService.post("research_news/list",param)
//         .then(({ data }) => {
//           if(data.data != ""){  
//             return data
//           }else{
//             return {"data" : 0}
//           }
//         })
//         .catch(({ response }) => {
//           return {"data" : 0}
//         });
//     }

//     @Action
//     [ActionsRe.CUST_NEWS_PARENT_CATEGORY](param) {
//       CustomMetamarketApiService.setHeader();
//       return CustomMetamarketApiService.post("research_news/dp_parent_category",param)
//         .then(({ data }) => {
//           if(data.data != ""){  
//             return data
//           }else{
//             return {"data" : 0}
//           }
//         })
//         .catch(({ response }) => {
//           return {"data" : 0}
//         });
//     }
    
//     @Action
//     [ActionsRe.CUST_NEWS_CHILD_CATEGORY](param) {
//       CustomMetamarketApiService.setHeader();
//       return CustomMetamarketApiService.post("research_news/dp_category",param)
//         .then(({ data }) => {
//           if(data.data != ""){  
//             return data
//           }else{
//             return {"data" : 0}
//           }
//         })
//         .catch(({ response }) => {
//           return {"data" : 0}
//         });
//     }
//     @Action
//     [ActionsRe.CUST_NEWS_TYPE](param) {
//       CustomMetamarketApiService.setHeader();
//       return CustomMetamarketApiService.post("research_news/dp_news_type_list",param)
//         .then(({ data }) => {
//           if(data.data != ""){  
//             return data
//           }else{
//             return {"data" : 0}
//           }
//         })
//         .catch(({ response }) => {
//           return {"data" : 0}
//         });
//     }
    
//     @Action
//     [ActionsRe.CUST_ADD_TAG](param) {
//       CustomMetamarketApiService.setHeader();
//       return CustomMetamarketApiService.post("research_tag/save",param)
//         .then(({ data }) => {
//           if(data.data != ""){  
//             return data
//           }else{
//             return {"data" : 0}
//           }
//         })
//         .catch(({ response }) => {
//           return {"data" : 0}
//         });
//     }

//     @Action
//     [ActionsRe.CUST_TAG_LIST](param) {
//       CustomMetamarketApiService.setHeader();
//       return CustomMetamarketApiService.post("research_tag/search",param)
//         .then(({ data }) => {
//           if(data.data != ""){  
//             return data
//           }else{
//             return {"data" : 0}
//           }
//         })
//         .catch(({ response }) => {
//           return {"data" : 0}
//         });
//     }

//     @Action
//     [ActionsRe.CUST_CAT_LIST](param) {
//       CustomMetamarketApiService.setHeader();
//       return CustomMetamarketApiService.post("research_category/list",param)
//       .then(({ data }) => {
//         if(data.data != ""){  
//           return data
//         }else{
//           return {"data" : 0}
//         }
//       })
//       .catch(({ response }) => {
//         return {"data" : 0}
//       });
//   }
//   @Action
//     [ActionsRe.CUST_PRICE_CURRENCY](param) {
//       CustomMetamarketApiService.setHeader();
//       return CustomMetamarketApiService.post("resaerch_currency/list",param)
//       .then(({ data }) => {
//         if(data.data != ""){  
//           return data
//         }else{
//           return {"data" : 0}
//         }
//       })
//       .catch(({ response }) => {
//         return {"data" : 0}
//       });
//   }
//   @Action
//   [ActionsRe.CUST_CITY_LIST](param) {
//     CustomMetamarketApiService.setHeader();
//     return CustomMetamarketApiService.post("research_city/list",param)
//     .then(({ data }) => {
//       if(data.data != ""){  
//         return data
//       }else{
//         return {"data" : 0}
//       }
//     })
//     .catch(({ response }) => {
//       return {"data" : 0}
//     });
// }

//   @Action
//   [ActionsRe.CUST_PRICE_INCOTERM](param) {
//       CustomMetamarketApiService.setHeader();
//       return CustomMetamarketApiService.post("research_incoterms/list",param)
//       .then(({ data }) => {
//         if(data.data != ""){  
//           return data
//         }else{
//           return {"data" : 0}
//         }
//       })
//       .catch(({ response }) => {
//         return {"data" : 0}
//       });
//   }

//   @Action
//   [ActionsRe.CUST_PRICE_DELIVERY_TERM](param) {
//     CustomMetamarketApiService.setHeader();
//     return CustomMetamarketApiService.post("research_delivery_terms/list",param)
//       .then(({ data }) => {
//         if(data.data != ""){  
//           return data
//         }else{
//           return {"data" : 0}
//         }
//       })
//       .catch(({ response }) => {
//         return {"data" : 0}
//       });
//   }

//   @Action
//   [ActionsRe.CUST_PRICE_INDEX_LIST](param) {
//       CustomMetamarketApiService.setHeader();
//       return CustomMetamarketApiService.post("research_price/crm_ptm_list",param)
//       .then(({ data }) => {
//         if(data.data.result_list != ""){  
//           return data
//         }else{
//           return {"data" : 0}
//         }
//       })
//       .catch(({ response }) => {
//         return {"data" : 0}
//       });
//   }

//   @Action
//   [ActionsRe.CUST_PRICE_EDIT](param) {
//       CustomMetamarketApiService.setHeader();
//       return CustomMetamarketApiService.post("research_price/edit",param)
//       .then(({ data }) => {
//         if(data.data.result_list != ""){  
//           return data
//         }else{
//           return {"data" : 0}
//         }
//       })
//       .catch(({ response }) => {
//         return {"data" : 0}
//       });
//   }
  
//   @Action
//   [ActionsRe.CUST_ADD_PRICE_INDEX](param) {
//       CustomMetamarketApiService.setHeader();
//       return CustomMetamarketApiService.post("research_price/save",param)
//       .then(({ data }) => {
//         if(data.data != ""){  
//           return data
//         }else{
//           return {"data" : 0}
//         }
//       })
//       .catch(({ response }) => {
//         return {"data" : 0}
//       });
//   }

//   @Action
//   [ActionsRe.CUST_PRICE_ADD](param) {
//       CustomMetamarketApiService.setHeader();
//     return CustomMetamarketApiService.post("research_price_daily_reading/save",param)
//       .then(({ data }) => {
//         if(data.data != ""){  
//           return data
//         }else{
//           return {"data" : 0}
//         }
//       })
//       .catch(({ response }) => {
//         return {"data" : 0}
//       });
//   }
  
//   @Action
//   [ActionsRe.CUST_PRICE_READING_LIST](param) {
//       CustomMetamarketApiService.setHeader();
//     return CustomMetamarketApiService.post("research_price/daily_readings_detail",param)
//       .then(({ data }) => {
//         if(data.data.result_list != ""){  
//           return data
//         }else{
//           return {"data" : 0}
//         }
//       })
//       .catch(({ response }) => {
//         return {"data" : 0}
//       });
//   }

//   @Action
//   [ActionsRe.CUST_PRICE_ATTRIBUTE](param) {
//       CustomMetamarketApiService.setHeader();
//     return CustomMetamarketApiService.post("research_price/product_attribute_list",param)
//       .then(({ data }) => {
//         if(data.data != ""){  
//           return data
//         }else{
//           return {"data" : 0}
//         }
//       })
//       .catch(({ response }) => {
//         return {"data" : 0}
//       });
//   }

//   @Action
//   [ActionsRe.CUST_ATTRIBUTE_VALUE_LIST](param) {
//       CustomMetamarketApiService.setHeader();
//     return CustomMetamarketApiService.post("research_price/dp_attribute_json_list",param)
//       .then(({ data }) => {
//         if(data.data != ""){  
//           return data
//         }else{
//           return {"data" : 0}
//         }
//       })
//       .catch(({ response }) => {
//         return {"data" : 0}
//       });
//   }
  
//   @Action
//   [ActionsRe.CUST_PRICE_ATTRIBUTE_SAVE](param) {
//       CustomMetamarketApiService.setHeader();
//     return CustomMetamarketApiService.post("research_price/price_template_attribute_save",param)
//       .then(({ data }) => {
//         if(data.data != ""){  
//           return data
//         }else{
//           return {"data" : 0}
//         }
//       })
//       .catch(({ response }) => {
//         return {"data" : 0}
//       });
//   }

  
//   @Action
//   [ActionsRe.CUST_FILE_UPLOAD](param) {
//       CustomMetamarketApiService.setHeader();
//     return CustomMetamarketApiService.post("research_price_daily_reading/bulk_insert",param)
//       .then(({ data }) => {
//         if(data.data != ""){  
//           return data
//         }else{
//           return {"data" : 0}
//         }
//       })
//       .catch(({ response }) => {
//         return {"data" : 0}
//       });
//   }

//   @Action
//   [ActionsRe.CUST_PRO_GRADE](param) {
//       CustomMetamarketApiService.setHeader();
//     return CustomMetamarketApiService.post("product/grade_list",param)
//       .then(({ data }) => {
//         if(data.data.result_list != ""){  
//           return data
//         }else{
//           return {"data" : 0}
//         }
//       })
//       .catch(({ response }) => {
//         return {"data" : 0}
//       });
//   }

//   @Action
//   [ActionsRe.CUST_PRO_STANDARD](param) {
//       CustomMetamarketApiService.setHeader();
//     return CustomMetamarketApiService.post("product/standard_list",param)
//       .then(({ data }) => {
//         if(data.data.result_list != ""){  
//           return data
//         }else{
//           return {"data" : 0}
//         }
//       })
//       .catch(({ response }) => {
//         return {"data" : 0}
//       });
//   }
  
//   @Action
//   [ActionsRe.CUST_ADD_PRO_GRADE](param) {
//       CustomMetamarketApiService.setHeader();
//     return CustomMetamarketApiService.post("product/grade_mapping",param)
//       .then(({ data }) => {
//         if(data.data != ""){  
//           return data
//         }else{
//           return {"data" : 0}
//         }
//       })
//       .catch(({ response }) => {
//         return {"data" : 0}
//       });
//   }

//   @Action
//   [ActionsRe.CUST_CAT_GRADE](param) {
//       CustomMetamarketApiService.setHeader();
//     return CustomMetamarketApiService.post("category/grade_list",param)
//       .then(({ data }) => {
//         if(data.data.result_list != ""){  
//           return data
//         }else{
//           return {"data" : 0}
//         }
//       })
//       .catch(({ response }) => {
//         return {"data" : 0}
//       });
//   }

//   @Action
//   [ActionsRe.CUST_CAT_STANDARD](param) {
//       CustomMetamarketApiService.setHeader();
//     return CustomMetamarketApiService.post("category/standard_list",param)
//       .then(({ data }) => {
//         if(data.data.result_list != ""){  
//           return data
//         }else{
//           return {"data" : 0}
//         }
//       })
//       .catch(({ response }) => {
//         return {"data" : 0}
//       });
//   }
  
//   @Action
//   [ActionsRe.CUST_ADD_CAT_GRADE](param) {
//       CustomMetamarketApiService.setHeader();
//     return CustomMetamarketApiService.post("category/grade_mapping",param)
//       .then(({ data }) => {
//         if(data.data != ""){  
//           return data
//         }else{
//           return {"data" : 0}
//         }
//       })
//       .catch(({ response }) => {
//         return {"data" : 0}
//       });
//   }
  
//   @Action
//   [ActionsRe.CUST_CATEGORY_GRADE_MAPPING_LIST](param) {
//       CustomMetamarketApiService.setHeader();
//     return CustomMetamarketApiService.post("category/grade_mapping_list",param)
//       .then(({ data }) => {
//         if(data.data.result_list != ""){  
//           return data
//         }else{
//           return {"data" : 0}
//         }
//       })
//       .catch(({ response }) => {
//         return {"data" : 0}
//       });
//   }

//   @Action
//   [ActionsRe.CUST_PRODUCT_GRADE_MAPPING_LIST](param) {
//       CustomMetamarketApiService.setHeader();
//     return CustomMetamarketApiService.post("product/grade_mapping_list",param)
//       .then(({ data }) => {
//         if(data.data.result_list != ""){  
//           return data
//         }else{
//           return {"data" : 0}
//         }
//       })
//       .catch(({ response }) => {
//         return {"data" : 0}
//       });
//   }
}