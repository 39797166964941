import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

// import AuthModule from "@/store/modules/AuthModule";
import CompanyModule from "@/store/modules/CompanyModule";
import ResearchModule from "@/store/modules/ResearchModule";

import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";

config.rawError = true;

const store = createStore({
  modules: {
    // AuthModule,
    CompanyModule,
    ResearchModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
  },
});

export default store;
