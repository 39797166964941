enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",

  CUST_VERIFY_AUTH = "custVerifyAuth",
  CUST_LOGIN = "custLogin",
  CUST_GET_COMPANY_LIST = "custCompList",
  CUST_GET_BRANCH_LIST = "custBranchList",
  CUST_GET_EDIT_BRANCH = "custBranchEdit",
  CUST_GET_KMP_COMP_LIST = "custKmpCompList",
  CUST_GET_KMP_LIST = "custKmpList",
  CUST_ADD_BRANCH = "custAddBranch",
  CUST_UPDATE_BRANCH = "custUpdateBranch",
  CUST_GET_PINCODE = "custPinCode",
  CUST_ADD_COMPANY = "custAddCompany",
  CUST_GET_COMPANY_PAGE_DATA = "custGetCompanyPageData",
  CUST_EDIT_COMPANY = "custEditCompany",
  CUST_ADD_KMP = "custAddKmp",
  CUST_UPDATE_KMP = "custUpdateKmp",
  CUST_LINK_KMP = "custLinkKmp",
  CUST_GET_DIN = "custGetDin",
  CUST_GET_COMPANY_TRADE_TYPE_LIST_DATA = "custGetCompanyTradeTypeListData",
  CUST_GET_ADDRESS_TITLE_LIST_DATA = "CustGetAddressTitleListListData",

  CUST_ADD_CONTACT = "custAddContact",
  CUST_UPDATE_CONTACT = "custUpdateContact",
  CUST_LIST_CONTACT = "custContactList",
  CUST_DESIGNATION_CONTACT = "custDesignationContact",

  CUST_PRODUCT_BRAND = "custProductBrand",

  CUST_ADD_CATEGORY = "custAddProdct",
  CUST_GET_INDUSTRY_CATEGORY = "custIndustryCategory",
  CUST_GET_SHAPE_CATEGORY = "custShapeCategory",
  CUST_GET_TAGLIST = "",
  CUST_GET_CATEGORY_LIST = "custCategoryList",
  CUST_GET_PARENT_CATEGORY = "custParentCategory",
  CUST_HSN_CATEGORY = "custHsnCategory",
  CUST_GET_PRODUCT_STANDARD_LIST = "custProductStandard",
  CUST_ADD_PRODUCT_STANDARD = "custAddProductStandard",
  CUST_GET_PRODUCT_STANDARD_MASTER_LIST = "custProductStandardMaster",
  CUST_ADD_PRODUCT_GRADE = "custProductGrade",
  CUST_GET_GRADE_LIST = "custGradeList",
  CUST_UPDATE_CATEGORY = "custUpdateCategory",
  CUST_UPDATE_PRODUCT_STANDARD = "custUpdateProductStandard",
  CUST_UPDATE_GRADE = "custUpdateGrade",
  CUST_GET_GRADE_STANDARD = "custGradeStandard",
  CUST_LINK_PRODUCT_STANDARD_CAT = "custLinkProd",
  CUST_ADD_ATTRIBUTE_LIST = "custAttributeList",
  CUST_GET_UOM_LIST = "custUomList",
  CUST_MAPPING_ATTRIBUTE = "custAttributeMapping",
  CUST_GET_ATTRIBUTE_LIST = "custAttrList",
  CUST_GET_PRODUCT_MAKING = "custProductMaking",
  CUST_GET_PRODUCT_COMPOSITION = "custProductComp",
  CUST_GET_PRODUCT_SHAPE = "custProductShape",
  CUST_ADD_PRODUCT = "custAddProduct",
  CUST_GET_PRODUCT_LIST = "custProductList",
  CUST_GET_PRODUCT_UPDATE = "custproductUpdate",
  CUST_GET_PRODUCT_ATTRIBUTE_LIST = "custProductAttributeList",
  CUST_ADD_TAG = "custAddTag",
  CUST_HSN_LIST = "custHsnList",
  CUST_ADD_HSN = "custAddHsn",
  CUST_UPDATE_HSN = "custUpdateHsn",

  CUST_GET_PRODUCT_BRAND = "custGetProductBrand",
  CUST_GET_COMPANY_BRAND = "custGetCompanyBrand",
  CUST_PRODUCT_UOM = "custProductUom",
  CUST_PRODUCT_CURRENCY = "cusProductCurrency",
  CUST_ADD_PRODUCT_PRICE = "custAddProductPrice",
  CUST_ADD_PRODUCT_INVENTORY = "custAddProductInventory",
  CUST_ADD_PRODUCT_BRAND = "custAddProductBrand",
  CUST_UPDATE_PRODUCT_BRAND = "custUpdateProductBrand",
  CUST_GET_PRODUCT_PRICE_LIST = "custPriceList",
  CUST_GET_PRODUCT_INVENTORY_LIST = "custInventoryList",
  CUST_PRODUCT_DELIVERY_TERM = "custProductDeliveryTerm",
  CUST_PRODUCT_INCOTERM = "custProductIncoterm",
  CUST_SELLER_BRANCH_LIST = "custSellerBranch",
  CUST_ADD_UOM_LIST = "custUomList",
  CUST_LINK_ATTRIBUTE_RANGE = "custSaveRange",
  CUST_ATTRIBUTE_RANGE_LIST = "custRangeList",
  CUST_GET_PRODUCT_SELECTBOX = "custProductSelectbox",
  CUST_GET_PRODUCT_ATTRIBUTE = "custProductAttribute",
  CUST_PRODUCT_ATTRIBUTE_VALUE_LIST = "custProductAttrValue",
  CUST_PRODUCT_MAPPING_ATTRIBUTE = "custProductAttrSave",
  CUST_PRODUCT_ATTRIBUTE_LIST_DISPLAY = "custProductAttributeListDisplay",
  CUST_GET_FORM_CATEGORY = "custFormCategory",
  CUST_GET_Stage_CATEGORY = "custStageCategory",
  CUST_GET_Length_CATEGORY = "custLengthCategory",
  CUST_GET_CATEGORY = "custCategorySearch",
  // CUST_REPORT_SAVE = "custReportSave",
  // CUST_REPORT_LIST = "custReportList",
  // CUST_REPORT_EDIT = "custReportEdit",

  // CUST_NEWS_LIST = "custNewsList",
  // CUST_NEWS_SAVE = "custNewsSave",
  // CUST_NEWS_EDIT = "custNewsEdit",
  // CUST_NEWS_PARENT_CATEGORY = "custParentCatList",

  CUST_ADD_CAT_IMAGE = "custCategoryImage",

  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  GET_COMPANY = "company/list",

  /////////////////////////RESEARCH APIS/////////////////////

  CUST_REPORT_LIST = "custReportList",
  CUST_REPORT_SAVE = "custReportSave",
  CUST_REPORT_EDIT = "custReportEdit",

  CUST_NEWS_LIST = "custNewsList",
  CUST_NEWS_SAVE = "custNewsSave",
  CUST_NEWS_EDIT = "custNewsEdit",
  CUST_NEWS_PARENT_CATEGORY = "custParentCatList",
  CUST_NEWS_CHILD_CATEGORY = "custChildCatList",
  CUST_NEWS_TYPE = "custNewsType",

  CUST_TAG = "custTag",
  CUST_TAG_LIST = "custTagList",

  CUST_PRICE_CURRENCY = "custPriceCurrencyy",
  CUST_PRICE_INCOTERM = "custPriceIncoterm",
  CUST_PRICE_DELIVERY_TERM = "custPriceDeliveryTerms",

  CUST_PRICE_INDEX_LIST = "custPriceIndexList",
  CUST_PRICE_EDIT = "custPriceEdit",
  CUST_CITY_LIST = "custCityList",
  CUST_CAT_LIST = "PriceCategoryList",

  CUST_ADD_PRICE_INDEX = "custSavePriceIndex",
  CUST_PRICE_ADD = "custPriceAdd",
  CUST_PRICE_READING_LIST = "custPriceReadingList",

  CUST_ATTRIBUTE_VALUE_LIST = "custAttributeValue",
  CUST_PRICE_ATTRIBUTE = "custAttribute",
  CUST_PRICE_ATTRIBUTE_SAVE = "custPriceAttrSave",

  CUST_FILE_UPLOAD = "custFileUpload",

  CUST_PRO_GRADE = "custProGrade",
  CUST_PRO_STANDARD = "custProStandard",
  CUST_ADD_PRO_GRADE = "custAddProGrade",

  CUST_CAT_GRADE = "custCatGrade",
  CUST_CAT_STANDARD = "custCatStandard",
  CUST_ADD_CAT_GRADE = "custAddCatGrade",

  CUST_PRODUCT_GRADE_MAPPING_LIST = "custProductGradeMapping",
  CUST_CATEGORY_GRADE_MAPPING_LIST = "custCategoryGradeMapping",

  CUST_PRODUCT_MATERIAL_CONDITION = "custProductMC",

}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",



}

export { Actions, Mutations };
